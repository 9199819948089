<template>
  <div class="steps">
    <steps direction="vertical" :active="active" active-color="#ff7700">
      <step v-for="(item, index) in list" :key="index">
        <h3>{{ item.title }}</h3>
        <p>{{ item.subTitle }}</p>
      </step>
    </steps>
  </div>
</template>
<script>
import { Step, Steps } from 'vant'
import basePage from '@/mixins/basePage'
export default {
  name: 'stepPage',
  mixins: [basePage],
  config: {
    title: '记录',
    fetchDataFn: 'fetchData',
  },
  components: {
    Step,
    Steps,
  },
  data() {
    return {
      active: 0,
      list: [],
    }
  },
  methods: {
    async fetchData() {
      const { url, data } = this.$_extractRequestInfoFormRoute()
      const result = await this.$_request({
        url: url || '/mock/ensure-steps',
        data,
      })
      const { body } = result
      this.active = body.active
      this.list = body.list
      return result
    },
  },
}
</script>
